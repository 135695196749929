<template>
  <div class="login-container">
    <div class="left"></div>
    <div class="right">
      <el-form ref="form" :model="form" :rules="rules" class="login-form" label-position="left">
        <div class="title">充电桩</div>
        <el-form-item style="margin-top: 40px" prop="userName">
          <span class="t_span">账号</span>
          <el-input v-model.number="username" placeholder="输入账号"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: -15px" prop="password">
          <span class="t_span">密码</span>
          <el-input type="password" v-model="password" placeholder="请输入您的密码"></el-input>
        </el-form-item>
        <!-- <el-form-item style="margin-top: -15px" prop="password">
          <div class="login-con">
            <el-checkbox v-model="checked">一周免登录</el-checkbox>
            <span @click="$message.warning('请联系管理员')">忘记密码？</span>
          </div>
        </el-form-item>-->
        <el-button :loading="loading" class="login-btn" type="primary" @click="btn">登录</el-button>
        <!-- <p class="login_no">
          还有没账号？<span @click="$message.warning('请联系管理员')"
            >立即注册</span
          >
        </p>-->
      </el-form>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import setting from "../utils/setting";
export default {
  data() {
    return {
      //   phone: 15955107032,
      //   password: '123...lll',
      // phone: 15255460858,
      // password: '000000',
      // // 生产员
      // phone: 15255460857
      // password: '000000',
      // 采购员
      // phone: 15255460859,
      username: "",
      password: "",
      setting: setting,
      loading: false
    };
  },
  methods: {
    btn() {
      this.loading = true;
      if (this.username != "wxb5222523") {
        this.loading = false;
        return this.$message.error("账号不正确");
      }

      if (this.password != "wxb5222523") {
        this.loading = false;
        return this.$message.error("密码不正确");
      }

      sessionStorage.setItem('username','wxb5222523')

      this.$router.push({ path: "/jiance" });

      // 100是管理员 101是销售 102是生产 103是采购 15955107032
      // Login({
      //   phone: this.phone,
      //   password: this.password,
      // }).then((res) => {
      //   this.loading = false;
      //   let user = {
      //     roles: res.data.roles[0],
      //     token: res.data.token,
      //   };
      //   if (res.code == 200) {
      //     if (user.roles == 100) {
      //       this.$router.push({ path: "/index" });
      //     } else if (user.roles == 101) {
      //       this.$router.push({ path: "/sales/customer" });
      //     } else if (user.roles == 102) {
      //       this.$router.push({ path: "/production/order" });
      //     } else if (user.roles == 103) {
      //       this.$router.push({ path: "/procurement/material" });
      //     }
      //     this.$store.commit("login", user);
      //     this.$store.commit("getUser");
      //   }
      // });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 50px;
  font-size: 15px;
}
/deep/ .el-form-item__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #2e5afb !important;
}
.login-container {
  width: 100%;
  height: 100vh;
  min-width: 1200px;
  //   background: url("../assets/background.jpg") center center fixed no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  .left {
    width: 50%;
    height: 100vh;
    background: red;
    background: url("../assets/login_bg.png") center center no-repeat;
    background-size: 100% 100%;
  }
  .right {
    width: 50%;
    height: 100vh;
    background: #fff;
    display: flex;
    .title {
      font-size: 30px;
      font-weight: 500;
      color: rgba(14, 18, 26, 1);
      font-weight: bold;
    }

    .t_span {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}

// .title-tips {
//   margin-top: 29px;
//   font-size: 26px;
//   font-weight: 400;
//   color: rgba(14, 18, 26, 1);
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

.login-btn {
  display: inherit;
  width: 400px;
  height: 50px;
  margin-top: 5px;
  border: 0;
  font-size: 14px;

  &:hover {
    opacity: 0.9;
  }
}

.login-con {
  width: 400px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  span {
    color: #c71b23;
    cursor: pointer;
    margin-right: 10px;
  }
  /deep/ .el-checkbox__label {
    font-size: 13px;
    color: #999999;
  }
}

.login_no {
  width: 400px;
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
  span {
    color: #2e5afb;
    cursor: pointer;
  }
}

.login-form {
  position: relative;
  width: 50%;
  margin: auto;
  //   margin: calc((100vh - 425px) / 2) 10% 10%;
  //   overflow: hidden;

  .forget-password {
    width: 100%;
    margin-top: 40px;
    text-align: left;

    .forget-pass {
      width: 129px;
      height: 19px;
      font-size: 20px;
      font-weight: 400;
      color: rgba(92, 102, 240, 1);
    }
  }

  .el-input {
    width: 390px;
  }
}
</style>


